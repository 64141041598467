import React from 'react'
import PropTypes from 'prop-types'
import defaultPage from './defaultPage'
import redirect from "../utils/redirect"
import Router from 'next/router'
import api from '../utils/api'
import { updateUser } from '../store'
import gql from 'graphql-tag'
import { ToastContainer, toast } from 'react-toastify';
import Moment from 'moment'
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
moment.locale("es")
import { graphql } from 'react-apollo'
import { compose } from 'recompose';
import { logout, invalidToken } from '../utils/auth'


export const Userfragments = {
  schedule: gql`
  fragment schedulesFragment on Schedule {
    id
    businesses_id
    doctors_id
    days{
      id
      day
      schedules_id
    }
    all_hrs
    shrink
    show_only
    timeranges{
      id
      opening_time
      closing_time
      schedules_id
    }
  }`,
  service: gql`
  fragment servicesFragment on Service {
    id
    name
    businesses_id
    duration
    doctors_id
  }`
}

Userfragments.user = gql`
fragment userFragment on User {
id
    email
    password
    first_name
    last_name
    signup_status
    businesses{
      id
      name
      address
      logo
      about
      subdomain
      website
      email
      users_id
      type
      simultaneous
      interval
      active
      facebook
      twitter
      confirm
      confirm_place
      use_services
      duration
      notifications_fromtime_web
      notifications_fromtime_app
      timestamp
      subscr_id
      mc_amount3
      trial_taken
      phone
      suscriptions{
        id
        starting
        ending
        txn_id
        code
        businesses_id
      }
      schedules{
        ...schedulesFragment
      }
      doctors{
        id
        name
        businesses_id
        interval
        schedules_id
        notifications_fromtime_web
        notifications_fromtime_app
        schedules{
          ...schedulesFragment
        }
        services{
          ...servicesFragment
        }
      }
      services{
        ...servicesFragment
      }
      requirements{
        id
        name
        related_to
      }
      requirementsBusinesses{
        requirements_id
        businesses_id
        requirement{
          id
          name
        }
      }
    }
  }
  ${Userfragments.schedule}
  ${Userfragments.service}
`

export const UserQuery = gql`
query{
  user{
    ...userFragment
}
}
${Userfragments.user}
`

const notificationSubscription = gql`
  subscription onNewNotification($businesses_id: Int!) {
    newNotification(businesses_id: $businesses_id) {
      id
      start_time
      timestamp
      last_action
    }
  }
`

const mensajesNotificaciones = {
  new: "Nueva cita",
  modified: "Se modificó cita",
  canceled: "Se canceló cita"
}

const redirectWelcomePage = (ctx) =>{
  console.log("redirecting not auth")
  if (ctx.res) {
    ctx.res.writeHead(302, { Location: "/" })
    ctx.res.end()
  } else {
    Router.replace("/welcomePage", "/")
  }
}

export const securePageHoc = Page => class SecurePage extends React.Component {
  static name2 = "Secure"

  static async getInitialProps(ctx, apolloClient, isAuthenticated) {
    console.log("getinitialprops secure", ctx.pathname)
    if (!isAuthenticated) {
      redirectWelcomePage(ctx)
      return
    }
    let user

    try {
      const res = await apolloClient.query({
        query: UserQuery
      })
      user = res.data.user;
      console.log("user", user, ctx.asPath)
      const regex = new RegExp("^/registro")
      if (!regex.test(ctx.asPath) && user.signup_status < 2) {
        console.log("entro al redirect")
        switch (user.signup_status) {
          default:
          case 0:
            redirect(ctx, "/registro/informacion-del-negocio", { type: "replace" })
            return
          case 1:
            redirect(ctx, "/registro/configuracion-de-calendario", { type: "replace" })
            return
        }
      }
    } catch (error) {
      return
    }
    if(!user){
      if (ctx.res) {
        ctx.res.writeHead(302, { Location: "/logout" })
        ctx.res.end()
      } else {
        Router.replace("/logout")
      }
      return
    }


    console.log("await ", Page.name2)
    const pageProps = Page.getInitialProps && await Page.getInitialProps(ctx, user, apolloClient)
    console.log("return secure")
    return {
      user,
      ...pageProps
    }
  }

  constructor(props) {
    super(props)
    this.logout = () => logout(props.client)
    console.log("constructor securepage")
  }

  componentDidMount() {
    console.log("secure mounted", this.props);
  }

  componentWillUnmount() {

  }


  componentDidUpdate(prevProps) {
    const { data } = prevProps
    const newNotification = data && data.newNotification
    console.log("didupdated", prevProps, this.props)
    const notification = this.props.data && this.props.data.newNotification
    if ((newNotification && newNotification.timestamp) != (notification && notification.timestamp)) {
      notification.start_time = moment(notification.start_time, 'YYYY-MM-DD HH:mm:ss')
      toast(mensajesNotificaciones[notification.last_action] + " para el " + notification.start_time.format("dddd D [de] MMMM [del] YYYY"));
      if (this.pageref.refresh)
        this.pageref.refresh()
    }
  }

  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired
  }
  render() {
    console.log("render secure")
    return <div>
      <Page ref={ref => this.pageref = ref} {...this.props} user={this.props.UserQuery.user} />
      <ToastContainer position="top-center" hideProgressBar={true} />
    </div>
  }
}
export default Page => defaultPage(
  compose(
    graphql(notificationSubscription, {
      options: (props) => {
        return {
          variables: {
            businesses_id: props.user && props.user.businesses[0] && props.user.businesses[0].id
          }
        }
      }
    }),
    graphql(UserQuery, { name: "UserQuery" })
  )(securePageHoc(Page))
)
