import React from 'react'
import Router from 'next/router'
import api from '../utils/api'
import NProgress from 'nprogress'
import { bindActionCreators } from 'redux'
import { initStore, updateUser } from '../store'
import withRedux from 'next-redux-wrapper'
import { getTokenFromCookie, getTokenFromLocalStorage } from '../utils/auth'
import withData from './withData'
import { graphql, withApollo } from 'react-apollo'
import { compose } from 'recompose';
import gql from 'graphql-tag'
import { securePageHoc } from './securePage'

console.log("hola 2")

const DefaultPageHoc = Page => class DefaultPage extends React.Component {
  static name2="Default"

  static async getInitialProps(ctx, apolloClient) {
    api.setCTX(ctx)
    console.log("getinitialprops default")
    const token = process.browser ? getTokenFromLocalStorage() : getTokenFromCookie(ctx.req)
    const isAuthenticated = !!token
    console.log("await ", Page.name2)
    const pageProps = Page.getInitialProps && await Page.getInitialProps(ctx, apolloClient, isAuthenticated)
    console.log("return default")
    return {
      ...pageProps,
      currentUrl: ctx.pathname,
      isAuthenticated
    }
  }

  constructor(props) {
    console.log("constructor default")
    super(props)
    this.logout = this.logout.bind(this)
  }

  logout(eve) {
    if (eve.key === 'logout') {
      console.log("se agrego logout", eve)
      Router.replace("/welcomePage", "/")
    }
    if (eve.key === 'login') {
      console.log("se agrego login")
      Router.replace("/")
    }
  }

  componentDidMount() {
    console.log("didmount")
    window.addEventListener('storage', this.logout, false)
  }

  componentWillUnmount() {
    console.log("didunmount")
    window.removeEventListener('storage', this.logout, false)
  }

  render() {
    console.log("render default")
    return <div>
      <Page {...this.props} />
    </div>
  }
}


export default Page => compose( withData, withApollo)(DefaultPageHoc(Page))
