import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from "apollo-link-context"
import fetch from 'isomorphic-unfetch'
import { BatchHttpLink } from "apollo-link-batch-http";
import { onError } from 'apollo-link-error'
import { invalidToken } from './utils/auth'
import { from, split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities'
import Api from './utils/api'

import config from './config'

let apolloClient = null


const customFetch = (uri, options) => {
  if (!Api.refreshing) {
    console.log("token not refreshing apollo", options.headers['x-access-token'])
    if (Api.token && (Api.token != options.headers['x-access-token'])) {
      options.headers['x-access-token'] = Api.token
      console.log("token different apollo", options.headers['x-access-token'])
    }
    return fetch(uri, options)
  }
  console.log("token refreshing apollo")
  return Api.refreshing.then(token => {
    options.headers['x-access-token'] = token
    console.log("token refreshed apollo", token)
    return fetch(uri, options)
  })
}

function create(initialState, { getToken, ctx }) {
  const httpLink = createHttpLink({
    uri: config.api + "/graphql",
    fetch: customFetch
  })

  const wsLink = process.browser && new WebSocketLink({
    uri: config.apiWS,
    options: {
      reconnect: true
    }
  });

  const authLink = setContext((_, { headers }) => {
    const token = getToken()
    console.log("getting token apollo", token)
    return {
      headers: {
        ...headers,
        ...token ? { 'x-access-token': token } : null
      }
    }
  })

  const logoutLink = onError(({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors){
      console.log('error graphql')
      graphQLErrors.map(({ message, locations, path, code }) => {
        console.warn(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path} Code: ${code}`,
        )
        if (message == 'must authenticate') {
          console.log("error token apollo")
          invalidToken(ctx)
        }
      }
      );
    }
    if (networkError) {
      //alert("There is no internet connection")
    }
  })

  const fromLink = from([
    authLink,
    logoutLink,
    httpLink
  ]);

  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser, // Disables forceFetch on the server (so queries are only run once)
    link: process.browser && split(
      // split based on operation type
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return wsLink && kind === 'OperationDefinition' && operation === 'subscription';
      },
      wsLink,
      fromLink) || fromLink,
    cache: new InMemoryCache(/* {
      dataIdFromObject: object => object.__typename+object.id || null
    } */).restore(initialState || {})
  })
}

export default function initApollo(initialState, options) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    console.log("graph recreating apollo client server side")
    return create(initialState, options)
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    console.log("graph recreating apollo client")
    apolloClient = create(initialState, options)
  }

  return apolloClient
}