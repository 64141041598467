import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { getDataFromTree } from 'react-apollo'
import Head from 'next/head'
import initApollo from '../initApollo'
import { getTokenFromCookie, getTokenFromLocalStorage } from '../utils/auth'
import { get } from 'https';
import { ApolloClient } from 'apollo-client';
import withRedux from '../hocs/withRedux'
import { initStore } from '../store'
import { Provider } from 'react-redux'

// Gets the display name of a JSX component for dev tools
function getComponentDisplayName(Component) {
  return Component.displayName || Component.name || 'Unknown'
}

export default ComposedComponent => {
  return class WithData extends React.Component {
    static displayName = `WithData(${getComponentDisplayName(
      ComposedComponent
    )})`

    static async getInitialProps(ctx, ctx2, ctx3) {
      // Initial serverState with apollo (empty)
      let serverState = {}
      console.log("getting initial props withdata")
      const apollo = initApollo(null, {
        getToken: () => process.browser ? getTokenFromLocalStorage() : getTokenFromCookie(ctx.req),
        ctx
      })
      // Evaluate the composed component's getInitialProps()
      let composedInitialProps = {}
      if (ComposedComponent.getInitialProps) {
        console.log("calling initial props from withdata")
        composedInitialProps = await ComposedComponent.getInitialProps(ctx, apollo)
      }

      // Run all GraphQL queries in the component tree
      // and extract the resulting data
      if (!process.browser) {
        if (ctx.res && ctx.res.finished) {
          // When redirecting, the response is finished.
          // No point in continuing to render
          return
        }

        try {
          console.log("graph about to running all querys")
          // Run all GraphQL queries
          const algo = await getDataFromTree(
            <ApolloProvider client={apollo}><Provider store={initStore()}><ComposedComponent url={{
              pathname: ctx.pathname,
              query: ctx.query,
            }} ctx={ctx} {...composedInitialProps} /></Provider></ApolloProvider>
          )
          console.log("graph running all querys")
        } catch (error) {
          console.error("error withdata running queries", error)
          // Prevent Apollo Client GraphQL errors from crashing SSR.
          // Handle them in components via the data.error prop:
          // http://dev.apollodata.com/react/api-queries.html#graphql-query-data-error
        }
        // getDataFromTree does not call componentWillUnmount
        // head side effect therefore need to be cleared manually
        Head.rewind()

        // Extract query data from the Apollo store
        serverState = apollo.cache.extract()

      }

      return {
        apollo: serverState,
        ...composedInitialProps
      }
    }

    constructor(props) {
      super(props)

      console.log("graph withdata constructor running from ", process.browser ? "browser" : "server")
      this.apollo = initApollo(props.apollo, {
        getToken: () => getTokenFromLocalStorage()
      })
      console.log("graph running constructor with data")
    }

    render() {
      let { ctx, ...props } = this.props;
      return (
        <ApolloProvider client={this.apollo}>
          <ComposedComponent {...props} />
        </ApolloProvider>
      )
    }
  }
}