import Router from 'next/router'

export default function redirect(ctx, path, options={}) {
    let defaults = {
        type : 'push',
        as: null,
        shallow: false
    };
    let actual = Object.assign({}, defaults, options);

    if (ctx.res) {
        console.log("server")
        const url = actual.as ? actual.as : path
        ctx.res.writeHead(302, { Location: url })
        ctx.res.end()
    } else {
        console.log("client")
        const as = actual.as ? actual.as : path
        if (actual.type == "replace")
            Router.replace(path, as, { shallow: actual.shallow })
        else
            Router.push(path, as, { shallow: actual.shallow })
    }
}